export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = phone => {
  const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
  return re.test(String(phone))
}

export const validateName = name => {
  return name && name.length > 2
}

export const validateAddress = address => {
  return address && address.length > 8
}

export const validatePostal = postal => {
  return postal && postal.length === 4 && !isNaN(postal)
}

export const validateAccept = accept => {
  return accept === true
}

export const validateFile = uploadUrls => {
  // this only validates if formData.uploadUrls is set (user selected a file)
  return uploadUrls && uploadUrls.length > 1
}

export const validateFields = (setErrorMsg, formData, errorMsgs) => {
  const isFamilyNameValid = validateName(formData.familyName)
  const isFirstNameValid = validateName(formData.firstName)
  const isEmailValid = validateEmail(formData.email)
  const isPhoneValid = validatePhone(formData.phone)
  const isAcceptValid = validateAccept(formData.accept)
  const isFileValid = validateFile(formData.uploadUrls)

  console.log("formData", formData)

  if (!isFamilyNameValid) {
    setErrorMsg(prevState => {
      return { ...prevState, name: errorMsgs.familyName }
    })
    document.querySelector('[name="familyName"]').classList.add("is-invalid")
  } else {
    document.querySelector('[name="familyName"]').classList.remove("is-invalid")
    document.querySelector('[name="familyName"]').classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, familyName: null }
    })
  }
  if (!isFirstNameValid) {
    setErrorMsg(prevState => {
      return { ...prevState, name: errorMsgs.firstName }
    })
    document.querySelector('[name="firstName"]').classList.add("is-invalid")
  } else {
    document.querySelector('[name="firstName"]').classList.remove("is-invalid")
    document.querySelector('[name="firstName"]').classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, firstName: null }
    })
  }
  if (!isEmailValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        email: errorMsgs.email,
      }
    })
    document.querySelector('[name="email"]').classList.add("is-invalid")
  } else {
    document.querySelector('[name="email"]').classList.remove("is-invalid")
    document.querySelector('[name="email"]').classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, email: null }
    })
  }
  if (!isPhoneValid) {
    setErrorMsg(prevState => {
      return { ...prevState, phone: errorMsgs.phone }
    })
    document.querySelector('[name="phone"]').classList.add("is-invalid")
  } else {
    document.querySelector('[name="phone"]').classList.remove("is-invalid")
    document.querySelector('[name="phone"]').classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, phone: null }
    })
  }

  if (!isAcceptValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        accept: `${errorMsgs.accept}`,
      }
    })
    document.querySelector('[name="accept"]').classList.add("is-invalid")
  } else {
    document.querySelector('[name="accept"]').classList.remove("is-invalid")
    document.querySelector('[name="accept"]').classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, accept: null }
    })
  }
  return (
    isFirstNameValid &&
    isFamilyNameValid &&
    isEmailValid &&
    isPhoneValid &&
    isAcceptValid &&
    isFileValid
  )
}

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Base64 Reader Error: ", error)
      reject(error)
    }
  })
}
