import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { FormGroup, Label, Input, Button } from "reactstrap"
import FileUploader from "./FileUploader"
import { validateFields, getBase64 } from "./validation"
import { StyledForm, MyStyledForm2 } from "./StyledForm"
import { Link } from "gatsby"

const MyForm3 = ({ job }) => {
  const [formData, setFormData] = useState({
    familyName: "",
    firstName: "",
    email: "",
    phone: "",
    uploadUrls: "",
    motivLetter: "",
    accept: false,
  })
  const [uploadFiles, setUploadFiles] = useState()
  const [motivLetter, setMotivLetter] = useState()
  const [errorMsg, setErrorMsg] = useState({
    familyName: null,
    firstName: null,
    email: null,
    phone: null,
    uploadUrls: null,
    motivLetter: null,
    accept: null,
  })

  const [submitted, setSubmitted] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  // translation
  const { t } = useTranslation()
  let errorMsgs = {}
  errorMsgs.familyName = "Kérjük, add meg a vezetékneved!"
  errorMsgs.firstName = "Kérjük, add meg a keresztneved!"
  errorMsgs.email = "Kérjük, add meg az email címed!"
  errorMsgs.phone = "Kérjük, add meg a telefonszámod!"
  errorMsgs.uploadUrls =
    "Kérjük, töltsd fel az önéletrajzod pdf vagy jpg formátumban!"
  errorMsgs.accept = "Kérjük, fogadd el az adatvédelmi tájékoztatót!"
  errorMsgs.motivLetter =
    "Kérjük, a megadott formátumban és fájlmérettel töltsd fel a dokumentumot."

  const base64ToFile = dataurl => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handleFileSelectionChange = e => {
    if (e.target.type === "file") {
      const updateKey = e.target.name
      const updateValue = e.target.value

      let newFormData = { ...formData, [updateKey]: updateValue }

      setFormData(newFormData)
      const isFormValid = validateFields(setErrorMsg, newFormData, errorMsgs)
      setBtnDisabled(!isFormValid)
      setUploadFiles([e.target.files[0]])
    }
  }

  const handleMotivLetterSelectionChange = e => {
    if (e.target.type === "file") {
      setMotivLetter([e.target.files[0]])
    }
  }

  const handleChange = async e => {
    const updateKey = e.target.name
    const updateValue = e.target.value
    let newFormData
    if (updateKey === "accept") {
      const updateChecked = e.target.checked
      newFormData = { ...formData, [updateKey]: updateChecked }
    } else {
      newFormData = { ...formData, [updateKey]: updateValue }
    }

    setFormData(newFormData)
    const isFormValid = validateFields(setErrorMsg, newFormData, errorMsgs)
    setBtnDisabled(!isFormValid)
  }

  const submitForm = async e => {
    e.preventDefault()

    if (validateFields(setErrorMsg, formData, errorMsgs)) {
      try {
        // disables the btn to prevent double submission
        setBtnDisabled(true)

        // UPLOAD CV

        let imageUrlRequests = uploadFiles.map((img, idx) => {
          return {
            index: idx,
            mime: img.type,
          }
        })

        // UPLOAD Motivation letter
        if (motivLetter) {
          // Add motivation letter mime type for requesting the url

          imageUrlRequests.push({
            index: 1,
            mime: motivLetter[0].type,
          })

          // Add motivation letter file
          uploadFiles.push(motivLetter[0])
        }

        // 1. call generate-url function to get aws urls in bulk
        // get the aws s3 urls in reponse
        const uploadUrlsResponse = await axios.post(
          "/.netlify/functions/generate-url",
          {
            urlRequests: imageUrlRequests,
          }
        )

        // UPLOAD CV and optionally Motivation letter
        for (let i = 0; i < uploadUrlsResponse.data.urls.length; i++) {
          // there is a single file in fileObjects this time, no need to use an index

          // use the iterator to select the corresponding file
          const file = uploadFiles[i]

          console.log(
            "uploading file to url",
            uploadUrlsResponse.data.urls[i].url
          )

          let base64Image = await getBase64(file)

          const uploadResponse = await axios.put(
            uploadUrlsResponse.data.urls[i].url,
            base64ToFile(base64Image)
          )
          console.log(
            `Document: ${uploadUrlsResponse.data.urls[i].url} uploaded successfully: `,
            uploadResponse.status
          )
        }

        // 2. Add CV (1) and optionally Motivation letter (2) url and job data to formData

        let newFormData = { ...formData }
        newFormData.uploadUrls =
          uploadUrlsResponse.data.urls[0].url.split("?")[0]

        if (uploadUrlsResponse.data.urls.length > 1) {
          newFormData.motivLetter =
            uploadUrlsResponse.data.urls[1].url.split("?")[0]
        }

        newFormData.job = job

        // 3. Upload data to google sheets via Zapier
        const uploadToSheetsResponse = await axios.post(
          "/.netlify/functions/set-sheet-row-karrier",
          newFormData
        )

        console.log(
          "Application uploaded to form successfully: ",
          uploadToSheetsResponse.status
        )

        // 4. Send email with form data

        const response = await axios.post(
          "/.netlify/functions/form-email-karrier",
          newFormData
        )

        console.log("Email sent successfully: ", response.status)

        // 5. SUBSCRIBE TO NEWSLETTER LIST - attach the Url of uploaded images to newFormData

        // let newsletterData = {
        //   email: `${formData.email}`,
        //   first_name: `${formData.firstName}`,
        //   last_name: `${formData.familyName}`,
        //   phone_number: `${formData.phone}`,
        //   address_line_1: `${formData.address}`,
        //   postal_code: `${formData.postal}`,
        //   custom_fields: {
        //     source: "karrier",
        //     origin_site: "https://cseriti.hu",
        //     leadActions: true,
        //     purchaseActions: false,
        //     accept: true,
        //   },
        // }

        // const newsletterResponse = await axios.post(
        //   "/.netlify/functions/newsletter-signup",
        //   newsletterData
        // )
        // console.log("Newsletter signup successful: ", newsletterResponse.status)
      } catch (e) {
        // handle errors
        console.log("erros happened: ", e)
      }

      // close
      setSubmitted(true)
      setUploadFiles(null)
      setTimeout(() => {
        setFormData({
          firstName: "",
          familyName: "",
          email: "",
          phone: "",
          uploadUrls: "",
          accept: false,
        })
        setErrorMsg({
          firstName: null,
          familyName: null,
          email: null,
          phone: null,
          uploadUrls: null,
          accept: null,
        })
        setSubmitted(false)
        setBtnDisabled(false)
      }, 8000)
    }
  }

  return (
    <MyStyledForm2 xs={12} md={{ size: 8 }} lg={{ size: 8, offset: 2 }}>
      <div>
        {submitted && (
          <span style={{ textTransform: "uppercase", fontSize: "2rem" }}>
            {`Kedves ${formData.firstName}!`}
          </span>
        )}
      </div>
      <div>
        {submitted ? (
          <div>
            <h2>Az állásjelentkezésed elküldve, köszönjük!</h2>
            <p>
              E-mail címedre visszaigazolást küldtünk. Amennyiben a
              visszaigazolás 1 órán belül nem érkezik meg, kérjük, jelezd a
              karrier[at]cseriti.hu címen!
            </p>
          </div>
        ) : (
          <StyledForm method="post" action="/thank-you">
            <input type="hidden" name="form-name" value="karrier" />
            {/* Bot submission honeypot */}
            <p hidden>
              <label>
                Don't fill this out:{" "}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <FormGroup className="user-data">
              <Label>
                Vezetéknév <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="familyName"
                value={formData["familyName"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">
                {errorMsg["familyName"]}
              </span>
              <Label>
                Keresztnév <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                value={formData["firstName"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">
                {errorMsg["firstName"]}
              </span>
              <Label>
                E-mail cím <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="email"
                value={formData["email"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">{errorMsg["email"]}</span>
              <Label>
                Telefonszám <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="phone"
                value={formData["phone"]}
                onChange={handleChange}
              />
              <span className="text-danger d-block">{errorMsg["phone"]}</span>
            </FormGroup>
            <Row>
              <Col xs="12">
                <Label>
                  Feltöltöm az önéletrajzom - kötelező{" "}
                  <span className="text-danger">*</span>
                  <br />
                  <span style={{ fontSize: ".8rem" }}>
                    .pdf, .jpeg, .doc, .docx formátum , maximum 5MB.{" "}
                  </span>
                </Label>
              </Col>
              <Col xs="12">
                <FileUploader
                  onFileSelectSuccess={event =>
                    handleFileSelectionChange(event)
                  }
                  onFileSelectError={({ error }) =>
                    setErrorMsg(prevState => {
                      return { ...prevState, uploadUrls: error }
                    })
                  }
                  fieldName={`uploadUrls`}
                  fieldTranslation={`Válaszd ki a fájlt!`}
                  errorMessage={errorMsg.uploadUrls}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Label>
                  Feltöltöm a motivációs levelemet - nem kötelező
                  <br />
                  <span style={{ fontSize: ".8rem" }}>
                    .pdf, .jpeg, .doc, .docx formátum , maximum 5MB.{" "}
                  </span>
                </Label>
              </Col>
              <Col xs="12">
                <FileUploader
                  onFileSelectSuccess={event =>
                    handleMotivLetterSelectionChange(event)
                  }
                  onFileSelectError={({ error }) =>
                    setErrorMsg(prevState => {
                      return { ...prevState, uploadUrls: error }
                    })
                  }
                  fieldName={`motivLetter`}
                  fieldTranslation={`Válaszd ki a fájlt!`}
                  errorMessage={errorMsg.motivLetter}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="checkbox"
                  name="accept"
                  className="ml-2 d-inline-block"
                  value={formData["accept"]}
                  onChange={handleChange}
                />
                <Label className="ml-5 d-inline-block">
                  <Link
                    style={{ textDecoration: "underline" }}
                    to="/hu/adatvedelem"
                  >
                    {t(`common:form.error.privacy`)}{" "}
                  </Link>
                  {t(`common:form.error.accept`)}
                </Label>
              </Col>
            </Row>
          </StyledForm>
        )}
      </div>
      {submitted ? (
        ""
      ) : (
        <div>
          <Button
            disabled={btnDisabled}
            color="primary"
            onClick={submitForm}
            className="text-uppercase purple-button mb-5"
          >
            {t("common:form.sendButton")}
          </Button>{" "}
        </div>
      )}
    </MyStyledForm2>
  )
}

MyForm3.propTypes = {
  job: PropTypes.object.isRequired,
}

MyForm3.defaultProps = {
  job: {
    name: "",
    description: [],
    jobType: "",
  },
}

export default MyForm3
