import React, { useState } from "react"
import { graphql } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import Seo from "../components/seo"
import { Container, Row, Col, Button } from "reactstrap"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import { sanitySerializers, sanityClientConfig } from "../utils/sanityConfig"
import { MediaQueries } from "../utils/responsive"
import Layout from "../components/layout"
import ShareButtons from "../components/SocialShareBar"
import HomeBackground from "../components/Visuals/homeBackground"
import { MdAccessTimeFilled, MdLocationOn } from "react-icons/md"
import { BsCalendarCheckFill } from "react-icons/bs"
import MyForm3 from "../components/Form3"

const StyledContainer = styled(Container)`
  background: ${props => props.theme.colors.white};
  .purple-button {
    background: rgb(73, 71, 157);
    border: rgb(73, 71, 157);
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    &:hover {
      background: rgb(43, 41, 160);
      border: rgb(43, 41, 160);
    }
  }

  .blog-title {
    padding: 3rem 0 0;

    margin-bottom: 0;
    color: ${props => props.theme.colors.purple};
    @media ${MediaQueries.desktop} {
      font-size: 3.2rem;
      padding: 3rem 0 0;
      margin-bottom: 1.45rem;
    }
  }
  .blog-date {
    color: ${props => props.theme.colors.darkgray};
    font-size: 1.4rem;
  }
  .blog-tags {
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0.5rem;
    background: ${props => props.theme.colors.purple};
    border-radius: 1rem;
    color: ${props => props.theme.colors.white};
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
  .blog-summary p {
    color: ${props => props.theme.colors.darkgray};
    padding-left: 1.5rem;
    font-size: 1.6rem;
  }

  .blog-blockcontent {
    margin-left: 1.5rem;
    @media ${MediaQueries.mobile} {
      margin: 3rem auto;
    }
    p {
      color: ${props => props.theme.colors.darkgray};
      font-size: 1.6rem;
    }
    a {
      color: ${props => props.theme.colors.pink};
      font-size: 1.6rem;
      &:hover {
        color: ${props => props.theme.colors.darkgray};
        font-size: 1.6rem;
      }
    }
  }
  .blog-blockquote {
    border-left: ${props => `2px solid ${props.theme.colors.purple}`};
    padding-left: 2rem;
    font-style: italic;
    font-size: 1.6rem;
    @media ${MediaQueries.mobile} {
      max-width: 75%;
      font-size: 2.4rem;
    }
  }
  .blog-sidebar {
    background-color: #fafafa;
  }

  .form-row {
    transition: all 0.5s ease-in-out;
  }
  .roll-up {
    height: 0;
    opacity: 0;
  }
  .roll-down {
    height: auto;
    opacity: 1;
  }
`

const SingleJobPage = ({ data, path }) => {
  const [open, setOpen] = useState(false)
  const job = data.sanityJob

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  let formattedDate = new Intl.DateTimeFormat("hu", dateOptions).format(
    new Date(job.publishedAt)
  )

  // provide a resized image (1200x630) for Facebook
  return (
    <Layout>
      <Seo
        title={`${job.title}`}
        description={
          job.title ||
          "Cseriti | Akár adsz, akár veszel, mindenképpen jót teszel"
        }
        // image={getImage(post.mainImage?.asset)?.images.fallback.src}
        path={path}
      />

      {/* Facebook pixel script */}
      <Helmet>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=1923256471276544&autoLogAppEvents=1"
          nonce="dIPn1kdy"
        ></script>
      </Helmet>
      <HomeBackground />
      <StyledContainer>
        <Container className="px-0">
          <section>
            <Row>
              <Col xs={12} className="main-holder">
                <Row>
                  <Col xs={12} lg={{ size: 8, offset: 2 }} className="mb-4 ">
                    <div className="">
                      <h1 className="blog-title">{job.title}</h1>
                      <p className="blog-date">
                        <BsCalendarCheckFill />{" "}
                        <i>Publikálva: {formattedDate || "Nincs dátum"}</i>
                      </p>
                      <p>
                        <MdLocationOn />
                        {` `}
                        {job.place}
                      </p>
                      <p>
                        <MdAccessTimeFilled />
                        {` `}
                        {job.jobType === "full"
                          ? "Teljes munkaidő"
                          : "Részmunkaidő"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} lg={{ size: 8, offset: 2 }}>
                    <SanityBlockContent
                      className="blog-blockcontent"
                      serializers={sanitySerializers}
                      blocks={job._rawDescription}
                      projectId={sanityClientConfig.sanity.projectId}
                      dataset={sanityClientConfig.sanity.dataset}
                      ignoreUnknownTypes={true}
                      renderContainerOnSingleChild={true}
                    />
                    <p>
                      <Button
                        onClick={() => setOpen(true)}
                        className="btn btn-primary purple-button"
                      >
                        Jelentkezem
                      </Button>
                    </p>
                  </Col>
                </Row>
                <Row
                  className={open ? "form-row roll-down" : "form-row roll-up"}
                >
                  <Col xs={12}>
                    <MyForm3 job={job} />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={{ size: 8, offset: 2 }}
                    className="sidebar-holder"
                  >
                    <ShareButtons url={job.slug.current} title={job.title} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        </Container>
      </StyledContainer>
    </Layout>
  )
}

export default SingleJobPage

export const query = graphql`
  query SingleJob($jobId: String) {
    sanityJob(_id: { eq: $jobId }) {
      slug {
        current
      }
      place
      jobType
      isPublic
      _rawDescription
      publishedAt
      description {
        _rawChildren
        children {
          marks
          text
        }
        style
      }
      id
      title
    }
  }
`
