import styled from "styled-components"
import { Col } from "reactstrap"
export const StyledForm = styled.form`
  label {
    margin-bottom: 0;
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 1.4rem !important;
  }
  .myForm-header {
    text-transform: uppercase;
    font-size: 2rem;
  }
`
export const MyStyledForm2 = styled(Col)`
  .highlight {
    color: ${props => props.theme.colors.pink};
  }
  .purple-button {
    background: rgb(73, 71, 157);
    border: rgb(73, 71, 157);
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    &:hover {
      background: rgb(43, 41, 160);
      border: rgb(43, 41, 160);
    }
  }
`
